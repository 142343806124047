<script lang="ts" setup>
const { $sentry } = useNuxtApp();
const { landingUrl } = useRuntimeConfig().public;

interface BlogPost {
  title: string;
  description: string;
  _path: string;
}

// Show blog post alert if the user has not read or dismissed the latest blog post and if the onboarding
// is not being shown.
const lastPostWasRead = ref(true);
const lastPost = ref<BlogPost>();
const { isAdmin } = storeToRefs(useUserStore());
const onboardingShown = useLocalStorage("onboarding-shown", false);
const show = computed(() => !lastPostWasRead.value && onboardingShown.value && isAdmin.value);

async function handleReadClick() {
  window.open(`${landingUrl}${lastPost.value?._path}`, "_blank");
  markPostRead();
}

function markPostRead() {
  if (lastPost.value) {
    const fromLocalStorage = useLocalStorage(`blog-${lastPost.value._path}`, false);
    fromLocalStorage.value = true;
    lastPostWasRead.value = true;
  }
}

onMounted(async () => {
  try {
    const posts = await $fetch<BlogPost[]>(`${landingUrl}/api/blog/posts`);
    if (!posts || !posts.length)
      return;

    lastPost.value = posts[0];
    lastPostWasRead.value = useLocalStorage(`blog-${lastPost.value._path}`, false).value;
  }
  catch (error) {
    $sentry.captureError(error);
  }
});
</script>

<template>
  <div v-auto-animate>
    <div
      v-if="show"
    >
      <UDivider
        class="mb-3"
        type="dashed"
      />
      <UAlert
        color="primary"
        variant="subtle"
        class="text-xs"
        :title="`🎉 New: ${lastPost?.title}`"
        :description="lastPost?.description"
        :close-button="{ icon: 'i-heroicons-x-mark-20-solid', color: 'primary', variant: 'link', padded: false }"
        :actions="[{ label: 'Read More', padded: false, click: handleReadClick }]"
        :ui="{
          title: 'text-xs font-semibold',
          description: 'text-xs',
        }"
        @close="markPostRead"
      />
    </div>
  </div>
</template>
