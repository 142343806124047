<script lang="ts" setup>
const { isAdmin } = storeToRefs(useUserStore());
const onboardingShown = useLocalStorage("onboarding-shown", false);

const show = computed(() => !onboardingShown.value && isAdmin.value);

function handleClick() {
  window.open(`${useRuntimeConfig().public.landingUrl}/blog/getting-started`, "_blank");
  onboardingShown.value = true;
}
</script>

<template>
  <div v-auto-animate>
    <div
      v-if="show"
    >
      <UDivider
        class="mb-3"
        type="dashed"
      />
      <UAlert
        color="primary"
        variant="subtle"
        class="text-xs"
        title="⚡ Let's get you started!"
        description="Read our guide to learn how to use the app. Don't worry, it only takes a few minutes."
        :close-button="{ icon: 'i-heroicons-x-mark-20-solid', color: 'primary', variant: 'link', padded: false }"
        :actions="[{ label: 'Read More', padded: false, click: handleClick }]"
        :ui="{
          title: 'text-xs font-semibold',
          description: 'text-xs',
        }"
        @close="onboardingShown = true"
      />
    </div>
  </div>
</template>
