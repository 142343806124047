<script setup lang="ts">
import { DcChatbotStatus, DcContentType } from "docuchatcommontypes";

const { $crisp, $sentry, $posthog } = useNuxtApp();
const { allowedLinks, goToMainPage } = useNavigation();
const { isAuthenticated, isUserDataFetched, user: userData, plan, organization: orgData } = storeToRefs(useUserStore());

// Set up the app once the user data is fetched
watch(isUserDataFetched, async (newIsUserDataFetched) => {
  try {
    if (newIsUserDataFetched) {
      if (!userData.value) {
        throw createError({
          message: "User data is fetched but there is no data.",
          fatal: true,
        });
      }

      if (!orgData.value) {
        throw createError({
          message: "Organization data is fetched but there is no data.",
          fatal: true,
        });
      }

      const id = userData.value.id;
      const email = userData.value.emailorUserName;
      const orgName = orgData.value.name;

      $sentry.setUser({
        id,
        email,
      });

      $crisp.setTokenId(id);
      $crisp.user.setEmail(email);
      $crisp.session.setData({
        user_id: id,
        plan: plan.value?.name ?? "Unknown",
      });

      $posthog.identify(id, email, orgName);
    }
  }
  catch (error) {
    $sentry.captureError(error);
  }
}, { immediate: true });

// Reset the app if the user is not authenticated for a certain time.
// We use timeout to make sure that the user has actually lost authentication before we reset the app.
let authTimeoutId: NodeJS.Timeout | null = null;

watch(isAuthenticated, (newIsAuthenticated) => {
  // Clear any existing timeout
  if (authTimeoutId !== null) {
    clearTimeout(authTimeoutId);
    authTimeoutId = null;
  }

  // If not authenticated, start a new timeout
  if (!newIsAuthenticated) {
    authTimeoutId = setTimeout(async () => {
      try {
        $sentry.setUser(null);

        if (window?.$crisp?.do instanceof Function)
          $crisp?.session?.reset();

        useUserStore().$reset();
        useChatbotStore().$reset();
        useDocumentStore().$reset();
        window.localStorage.clear();
        window.sessionStorage.clear();
        $posthog.reset();

        await navigateTo("/signin");
      }
      catch (error) {
        $sentry.captureError(error);
      }
    }, 5000); // 5 seconds delay
  }
});

// Watch chatbot and document statuses to send notifications
// TODO: We should also poll for when a Google Drive folder is being synced (meaning there is a file inside the document where lastSyncState is "Syncing")
const waitTimeIndex = ref(0);
let statusTimeoutId: NodeJS.Timeout | null = null;

function getNextWaitTime() {
  const waitTimes = [1, 2, 3, 5];
  const currentWaitTime = waitTimes[waitTimeIndex.value] * 1000;

  if (waitTimeIndex.value < waitTimes.length - 1)
    waitTimeIndex.value = waitTimeIndex.value + 1;

  return currentWaitTime;
}

function shouldWatch(status: DcChatbotStatus) {
  const statusesToWatch = [DcChatbotStatus.Initialized, DcChatbotStatus.Processing, DcChatbotStatus.Queued];
  return statusesToWatch.includes(status);
}

async function checkStatuses(documentIds: string[], chatbotIds: string[]) {
  const waitTime = getNextWaitTime();

  // Clear the previous timeout if it exists
  if (statusTimeoutId)
    clearTimeout(statusTimeoutId);

  statusTimeoutId = setTimeout(async () => {
    const documentStatuses = documentIds.length ? await useDocument().fetchDocumentStatuses(documentIds) : [];
    const chatbotStatuses = chatbotIds.length ? await useChatbot().fetchChatbotStatuses(chatbotIds) : [];

    chatbotStatuses.forEach((bot) => {
      const botFromStore = useChatbotStore().getChatbotFromCacheById(bot.id);
      if (!botFromStore)
        return;
      botFromStore.status = bot.status;

      if (!shouldWatch(bot.status)) {
        useChatbot().validateCanChat(botFromStore.id, botFromStore.status);
        useChatbot().validateChatbot(botFromStore);
      }
    });

    let googleDriveDocumentProcessed = false;
    documentStatuses.forEach((doc) => {
      const docFromStore = useDocumentStore().getDocumentFromCacheById(doc.id);
      if (!docFromStore)
        return;
      docFromStore.status = doc.status;

      if (!shouldWatch(doc.status)) {
        // Send notification
        useDocument().sendDocumentStatusNotification(docFromStore.name, docFromStore.status);

        if (docFromStore.contentType === DcContentType.GoogleDriveFolder)
          googleDriveDocumentProcessed = true;
      }
    });

    // HACK: We should normally use fetchDocumentStatuses but it is not updated to cover Google Drive folders
    if (googleDriveDocumentProcessed)
      await useDocumentStore().fetchDocuments(false);

    const allStatuses = [...documentStatuses, ...chatbotStatuses];

    if (allStatuses.every(s => !shouldWatch(s.status))) {
      if (statusTimeoutId) {
        clearTimeout(statusTimeoutId);
        statusTimeoutId = null;
      }
    }
    else { checkStatuses(documentIds, chatbotIds); }
  }, waitTime);
};

const { documents } = storeToRefs(useDocumentStore());
const { userChatbots: chatbots } = storeToRefs(useChatbotStore());

watch([documents, chatbots], ([newDocuments, newChatbots]) => {
  const documentsToWatch = newDocuments.filter(document => shouldWatch(document.document.status));
  const chatbotsToWatch = newChatbots.filter(chatbot => shouldWatch(chatbot.status));

  console.log("Should watch", documentsToWatch);

  if (!documentsToWatch.length && !chatbotsToWatch.length)
    return;

  const documentIds = documentsToWatch.map(document => document.document.id);
  const chatbotIds = chatbotsToWatch.map(chatbot => chatbot.id);

  waitTimeIndex.value = 0;
  checkStatuses(documentIds, chatbotIds);
}, { immediate: true });

// We navigate away and clear error, otherwise the error will be shown again.
async function handleErrorGoBack(clearError: () => void) {
  await goToMainPage();
  clearError();
}
</script>

<template>
  <UDashboardLayout>
    <UDashboardPanel
      :width="265"
      collapsible
    >
      <UDashboardNavbar>
        <template #left>
          <button @click.prevent="navigateTo('/user/chatbots')">
            <Logo class="h-6 w-auto" />
          </button>
        </template>

        <template #right>
          <UColorModeButton />
        </template>
      </UDashboardNavbar>

      <UDashboardSidebar>
        <UDashboardSidebarLinks :links="allowedLinks.main.map(i => ({ ...i, children: undefined }))" />

        <TheAlertOnboarding />
        <TheAlertBlogPost />
        <TheModalOrganizationWelcome />
        <TheModalSubscribe />

        <div class="flex-1" />

        <UDashboardSidebarLinks :links="allowedLinks.footer.map(i => ({ ...i, children: undefined }))" />

        <UDivider class="sticky bottom-0" />

        <template #footer>
          <TheUserDropdown />
        </template>
      </UDashboardSidebar>
    </UDashboardPanel>

    <!-- Main Content -->
    <NuxtErrorBoundary @error="(error:any) => $sentry.captureError(error)">
      <!-- We wrap with suspense to avoid rarely getting this issue: https://github.com/nuxt/nuxt/issues/13309 -->
      <Suspense>
        <slot />
      </Suspense>

      <template #error="{ error, clearError }">
        <UDashboardPage>
          <UDashboardPanel grow>
            <UDashboardPanelContent>
              <UPageHeader
                :headline="error?.value?.statusCode?.toString()"
                title="Sorry, something went wrong."
                :description="error?.value?.message"
                class="border-0"
              >
                <p class="mt-4">
                  Our team has been alerted about the error and will investigate it promptly to prevent future occurrences.
                </p>
                <div class="mt-4 flex items-center gap-4">
                  <UButton @click.prevent="clearError">
                    Try Again
                  </UButton>
                  <UButton
                    variant="soft"
                    @click.prevent="handleErrorGoBack(clearError)"
                  >
                    Go Back
                  </UButton>
                </div>
              </UPageHeader>
            </UDashboardPanelContent>
          </UDashboardPanel>
        </UDashboardPage>
      </template>
    </NuxtErrorBoundary>
  </UDashboardLayout>
</template>
